import { cva } from '@/utils/classnames/cva';
import { classnames } from '@/utils/classnames/classnames';
import { asClassStyle } from '@/components/utils/helpers/variants';
import { SearchVariant } from '../../types';

const useClassNames = (variant: SearchVariant, focused: boolean, disabled: boolean) => {
  const resolveSearchBar = cva({
    xs: classnames(
      'rounded-md',
      focused ? 'border-neutral-800 shadow-200' : 'border-gray-300',
      disabled ? 'bg-neutral-200' : 'border bg-white-100',
    ),
    sm: classnames(
      'rounded-md',
      focused ? 'border-neutral-800 shadow-200' : 'border-gray-300',
      disabled ? 'bg-neutral-200' : 'border bg-white-100',
    ),
    lg: 'gap-3 rounded-lg bg-white-300 p-3',
  });

  const resolveSearchInput = cva({
    xs: classnames(
      asClassStyle('b') as string,
      'placeholder:text-secondary disabled:cursor-not-allowed disabled:placeholder:text-gray-300',
    ),
    sm: classnames(
      asClassStyle('b') as string,
      'placeholder:text-secondary disabled:cursor-not-allowed disabled:placeholder:text-gray-300',
    ),
    lg: classnames(
      asClassStyle('b') as string,
      'rounded-lg bg-white-300 placeholder:text-secondary disabled:cursor-not-allowed disabled:placeholder:text-gray-300',
    ),
  });

  // const resolveSearchButton = cva({
  //   xs: classnames('rounded-r-md bg-white-100 px-3 py-2 disabled:cursor-not-allowed disabled:bg-neutral-200'),
  //   sm: classnames('rounded-r-md bg-white-100 p-3 disabled:cursor-not-allowed disabled:bg-neutral-200'),
  //   lg: classnames('h-6 bg-white-300'),
  // });

  const searchBarClassNames = classnames('flex', resolveSearchBar(variant) as string);

  const searchInputClassNames = classnames(
    'box-content w-full border-none transition focus:outline-none disabled:bg-neutral-200',
    resolveSearchInput(variant) as string,
  );

  const searchButtonClassNames = classnames('z-[5] shrink-0 transition' /*, resolveSearchButton(variant) as string*/);

  const searchIconClassNames = classnames('h-6 w-6 text-primary');

  return {
    searchBarClassNames,
    searchInputClassNames,
    searchButtonClassNames,
    searchIconClassNames,
  };
};

export default useClassNames;
