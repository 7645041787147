import React, { useContext } from 'react';
import Typography from '@/components/atoms/typography';
import Link from '@/components/atoms/link';
import { HeaderContext } from '../context';

const AccountLink = () => {
  const { accountLink, accountLinkLabel } = useContext(HeaderContext);

  return (
    <Link
      href={accountLink.href ?? '/'}
      className="flex items-center justify-center gap-2"
      underline={false}
      underlineOnHover={false}
    >
      <Typography classStyle="h6" className="uppercase">
        {accountLinkLabel}
      </Typography>
    </Link>
  );
};

export default AccountLink;
