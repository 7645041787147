import { Category } from '@shared/types/product';
import { CategoryBreadcrumb } from '@/components/organisms/product-selection-list/types';
import { CategoryListItemProps } from '@/providers/cart/types';
import { Product } from '@/types/entity/product';

export const findCategoryBySlug = (categories: Category[], slug: string): Category | undefined => {
  for (const category of categories) {
    if (category.slug === slug) return category;

    const subcategory = findCategoryBySlug(category.subCategories, slug);
    if (subcategory) return subcategory;
  }
  return undefined;
};

export const findParentCategory = (
  categoryId: string,
  categories: CategoryListItemProps[],
): CategoryListItemProps | undefined => {
  for (const category of categories) {
    if (category.id === categoryId) return category;

    const parentCategory = findParentCategory(categoryId, category.subCategories);
    if (parentCategory) return parentCategory;
  }
  return undefined;
};

export const findParentCategoryIds = (categoryId: string, categories: CategoryListItemProps[]): string[] => {
  const parentCategory = findParentCategory(categoryId, categories);
  if (parentCategory) {
    const parentIds = parentCategory.parentId ? findParentCategoryIds(parentCategory.parentId, categories) : [];
    return [...parentIds, parentCategory.id];
  }
  return [];
};

export const findParentCategoryInfo = (
  categoryId: string,
  categories: CategoryListItemProps[],
): CategoryBreadcrumb[] => {
  const parentCategory = findParentCategory(categoryId, categories);
  if (parentCategory) {
    const parentInfo = parentCategory.parentId ? findParentCategoryInfo(parentCategory.parentId, categories) : [];
    return [...parentInfo, { name: parentCategory.name, link: parentCategory.slug }];
  }
  return [];
};

export const filterCategoriesByName = (
  categories: CategoryListItemProps[],
  searchValue: string,
): CategoryListItemProps[] => {
  const filterRecursively = (categories: CategoryListItemProps[], searchValue: string): CategoryListItemProps[] => {
    return categories.reduce<CategoryListItemProps[]>((acc, category) => {
      if (category.name.toLowerCase().includes(searchValue.toLowerCase())) {
        acc.push(category);
      } else {
        const filteredSubCategories = filterRecursively(category.subCategories, searchValue);
        if (filteredSubCategories.length > 0) {
          acc.push({ ...category, subCategories: filteredSubCategories });
        }
      }
      return acc;
    }, []);
  };

  return filterRecursively(categories, searchValue);
};

export const expandCategories = (
  categories: CategoryListItemProps[],
  expandedCategories: { [key: string]: boolean },
  searchValue: string,
) => {
  const expandRecursively = (categories: CategoryListItemProps[], parentIds: string[]) => {
    categories.forEach((category) => {
      if (category.name.toLowerCase().includes(searchValue.toLowerCase())) {
        parentIds.forEach((parentId) => {
          expandedCategories[parentId] = true;
        });
      } else {
        expandRecursively(category.subCategories, [...parentIds, category.id]);
      }
    });
  };

  expandRecursively(categories, []);
};

const collectCategoryIdsFromProducts = (products: Product[]): Set<string> => {
  const categoryIds = new Set<string>();
  products.forEach((product) => {
    product.categories?.forEach((category) => {
      categoryIds.add(category.categoryId);
    });
  });

  return categoryIds;
};

const filterCategories = (
  categories: CategoryListItemProps[],
  validCategoryIds: Set<string>,
): CategoryListItemProps[] => {
  return categories.reduce<CategoryListItemProps[]>((acc, category) => {
    const filteredSubCategories = filterCategories(category.subCategories, validCategoryIds);
    if (validCategoryIds.has(category.id) || filteredSubCategories.length > 0) {
      acc.push({ ...category, subCategories: filteredSubCategories });
    }

    return acc;
  }, []);
};

export const filterCategoriesByProducts = (
  categories: CategoryListItemProps[],
  products: Product[],
): CategoryListItemProps[] => {
  const validCategoryIds = collectCategoryIdsFromProducts(products);

  return filterCategories(categories, validCategoryIds);
};
