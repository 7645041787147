import { ProductSuggestion } from '@/components/organisms/search/types';
import { Product } from '@shared/types/product';
import { mapProduct } from './map-product';

export const mapProductSuggestion = (product: Product): ProductSuggestion => {
  const variant = product.variants[0];

  const productMapped = mapProduct(product);

  return {
    id: productMapped.id,
    sku: variant?.sku ?? '',
    productKey: productMapped?.productKey ?? '',
    name: productMapped.name ?? '',
    description: variant?.attributes?.['Product-Specifications'],
    image: variant?.images?.[0],
    url: productMapped.url,
    inStock: variant?.isOnStock,
    restockDate: variant?.expectedDelivery ?? '',
    maxQuantity: variant.isOnStock ? variant.availableQuantity : 0,
    specifications: productMapped.specifications,
  } as ProductSuggestion;
};
