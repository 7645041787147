'use client';

import { ChangeEvent, FC, useState } from 'react';
import Input from '@/components/atoms/input';
import PasswordInput from '@/components/atoms/password-input';
import { Account } from '@shared/types/account/Account';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import Checkbox from '@/components/atoms/checkbox';
import Link from '@/components/atoms/link';
import useCustomRouter from '@/hooks/useCustomRouter';
import AuthLayout from '../layouts/auth-layout';
import { LoginProps } from './types';
import AuthForm from '../layouts/auth-form';

const Login: FC<LoginProps> = ({ login, ...props }) => {
  const router = useCustomRouter();

  const { translate } = useTranslation();

  const [data, setData] = useState<Account & { rememberMe?: boolean }>({});
  const [error, setError] = useState<string>();
  const [inputError, setInputError] = useState<string>();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (error) setError(undefined);

    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const handleChecked = (isChecked: boolean) => setData({ ...data, rememberMe: isChecked });

  const handleLoginSubmit = async () => {
    if (data.email && data.password) {
      login(data.email, data.password, data.rememberMe)
        .then(() => {
          router.refresh();
          router.push('/');
        })
        .catch((err: Error) => {
          if (err.message.includes('unverified')) {
            setInputError(translate('error.auth.action.verify'));
          } else {
            setError(translate('error.auth.wrong'));
          }
        });
    } else {
      setError(translate('error.auth.wrong'));
    }
  };

  return (
    <AuthLayout {...props}>
      <AuthForm
        onSubmit={handleLoginSubmit}
        title={translate('account.account.login.headline')}
        buttonLabel={translate('account.account.login')}
        error={error}
      >
        <Input
          containerClassName="w-full"
          className="w-full"
          name="email"
          required
          label={translate('common.emailAddress')}
          placeholder={translate('common.emailAddress.placeholder')}
          value={data.email ?? ''}
          onChange={handleChange}
          error={inputError}
        />

        <PasswordInput
          containerClassName="w-full"
          className="w-full"
          name="password"
          required
          label={translate('account.password')}
          placeholder={translate('account.password.placeholder')}
          value={data.password ?? ''}
          onChange={handleChange}
        />

        <div className="mt-6 flex justify-between gap-6">
          <Checkbox name="rememberMe" label={translate('account.rememberMe')} onChecked={handleChecked} />
          <Link classStyle="b2" href="/forgot-password">
            {translate('account.password.forgot')}
          </Link>
        </div>
      </AuthForm>
    </AuthLayout>
  );
};

export default Login;
