import React from 'react';
import HighlightBanner from '@/components/organisms/highlight-banner';
import { resolveReference } from '@/utils/lib/resolve-reference';
import { TasticProps } from '../types';
import { HighlightBannerTasticProps } from './types';

const HighlightBannerTastic = ({ data }: TasticProps<HighlightBannerTasticProps>) => {
  return (
    <HighlightBanner
      title={data.title}
      description={data.description}
      image={data.image}
      link={resolveReference(data.link)}
      linkLabel={data.linkLabel}
    />
  );
};

export default HighlightBannerTastic;
