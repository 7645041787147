'use client';

import React from 'react';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import Image from '@/components/atoms/Image';
import Link from '@/components/atoms/link';
import Typography from '@/components/atoms/typography';
import { CommercialOperationListItemProps } from './types';

const CommercialOperationListItem = ({ title, link, image, products }: CommercialOperationListItemProps) => {
  const { translate } = useTranslation();

  return (
    <div className="flex">
      <div className="relative block h-24 w-24">
        <Image src={image?.src} fill style={{ objectFit: 'cover' }} alt={title ?? ''} />
      </div>

      <div className="flex flex-col p-4">
        <Typography classStyle="h6">{title}</Typography>
        <Typography classStyle="b2" className="mb-2">
          {translate('product.references', { values: { count: products.toString() } })}
        </Typography>

        <Link
          href={link?.href ?? '#'}
          classStyle="h6"
          openInNewTab={link?.openInNewTab}
          className="mt-2 !text-pink-500"
          underline={false}
        >
          {translate('product.see.content')}
        </Link>
      </div>
    </div>
  );
};

export default CommercialOperationListItem;
