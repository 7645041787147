import React, { useState } from 'react';
import Select from '@/components/atoms/select';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { useShipAndLanguage } from '@/providers/ship-and-language';
import Button from '@/components/atoms/button';
import { ProductSuggestion } from '@/components/organisms/search/types';
import AddItemToCartForm from '@/components/organisms/cart-add-item-popup';
import Search from '@/components/organisms/search';
import QuickOrderDesktop from '@/components/organisms/quick-order/quick-order-desktop';
import Logo from './logo';
import { HeaderProvider } from './context';
import CartLink from './cart-link';
import { HeaderProps } from './types';
import AccountLink from './account-link';
import OrdersLink from './orders-link';

const Header = ({
  searchPlaceholder,
  searchSuggestions,
  headerSearch,
  onHeaderSearch,
  onHeaderSearchAction,
  selectedBusinessUnit,
  businessUnits,
  onBusinessUnitChange,
  ...props
}: HeaderProps) => {
  const { translate } = useTranslation();

  const { locations, selectedLanguage, onLanguageSelect } = useShipAndLanguage();

  const [showAddToCartModal, setShowAddToCartModal] = useState(false);

  const [searchedProduct, setSearchedProduct] = useState<ProductSuggestion>();

  const onlyOneBusinessUnit = businessUnits.find((bu) => bu.value === selectedBusinessUnit)?.name;

  return (
    <HeaderProvider {...props} businessUnits={businessUnits}>
      <header>
        <div className="flex items-center justify-between border-b border-neutral-400 bg-white-100 px-8 py-4">
          <div className="flex items-center gap-x-2 md:gap-x-4 lg:gap-x-8">
            <div className="w-[100px]">
              <Logo {...props} />
            </div>

            <div className="hidden w-[350px] lg:block xl:w-[450px]">
              <Search
                variant="lg"
                scrollControl
                searchValue={headerSearch}
                placeholder={searchPlaceholder}
                suggestions={searchSuggestions}
                filterSearch={false}
                handleOnChange={onHeaderSearch}
                handleSearchAction={onHeaderSearchAction}
                onProductClick={(product: ProductSuggestion) => {
                  setShowAddToCartModal(true);
                  setSearchedProduct(product);
                }}
              />
            </div>

            {showAddToCartModal && (
              <AddItemToCartForm close={() => setShowAddToCartModal(false)} productSuggestion={searchedProduct} />
            )}
          </div>
          <div className="flex items-center gap-x-6">
            {businessUnits.length === 1 ? (
              <Button type="button" variant="bordered">
                {onlyOneBusinessUnit ?? businessUnits[0].name}
              </Button>
            ) : (
              <Select
                defaultValue={selectedBusinessUnit ?? translate('common.select')}
                options={businessUnits}
                className="!w-auto"
                onChange={(val) => onBusinessUnitChange && onBusinessUnitChange(val, true)}
              />
            )}

            <div className="h-[24px] border-l border-primary opacity-[16%]" />

            <OrdersLink />
            <CartLink />
            <AccountLink />

            <div className="hidden lg:block">
              <QuickOrderDesktop
                downloadLink={props.csvDownloadLink}
                searchText={props.quickOrderSearch}
                onSearch={props.onQuickOrderSearch}
                items={props.quickOrderProducts}
                addItem={props.addToCart}
                csvProducts={props.csvShowProducts}
                handleSKUsUpdate={props.handleSKUsUpdate}
                addItemDisabled={props.addToCartDisabled}
              />
            </div>

            <Select
              defaultValue={selectedLanguage?.value ?? translate('common.select')}
              options={locations.flatMap((location) => location.languages)}
              onChange={onLanguageSelect}
            />
          </div>
        </div>

        <div className="block w-full border-b bg-white-100 md:px-3 lg:hidden">
          <Search
            variant="lg"
            scrollControl
            placeholder={searchPlaceholder}
            searchValue={headerSearch}
            suggestions={searchSuggestions}
            filterSearch={false}
            handleOnChange={onHeaderSearch}
            handleSearchAction={onHeaderSearchAction}
          />
        </div>
      </header>
    </HeaderProvider>
  );
};

export default Header;
