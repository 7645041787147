import { classnames } from '@/utils/classnames/classnames';
import { cva } from '@/utils/classnames/cva';
import { Variant } from '../../types';

const useClassNames = (variant: Variant) => {
  const resolveVariant = cva({
    intent: {
      primary: 'bg-blue-500 text-primary',
      secondary: 'bg-neutral-200 text-white-100',
      tertiary: 'bg-pink-900 text-white-100',
      warning: 'bg-yellow-500 text-primary',
      success: 'bg-green-500 text-primary',
      danger: 'bg-pink-500 text-white-100',
    },
  });

  const className = classnames(
    'w-fit rounded-lg px-2 py-1 font-manrope text-12 uppercase leading-4',
    resolveVariant(`intent.${variant}`) as string,
  );

  return { className };
};

export default useClassNames;
