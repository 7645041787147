import { SDK, ServerOptions } from '@commercetools/frontend-sdk';
import { CommercialOperation } from '@shared/types/custom-object/CommercialOperation';
import { ComposableCommerceEventsB2B } from '../../types/events/ComposableCommerceEventsB2B';
import { GetCustomObjectAction } from '../../types/actions/CustomObjectActions';
import { GetCustomObjectQuery } from '../../types/queries/CustomObjectQueries';

export type CustomObjectActions = {
  getCommercialOperation: GetCustomObjectAction;
};

export const getCustomObjectActions = (sdk: SDK<ComposableCommerceEventsB2B>): CustomObjectActions => {
  return {
    getCommercialOperation: async (
      query: GetCustomObjectQuery,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      const response = await sdk.callAction<CommercialOperation>({
        actionName: 'customObject/getCommercialOperationByKey',
        query,
        serverOptions: options?.serverOptions,
      });
      return response;
    },
  };
};
