import React from 'react';
import { Listbox } from '@headlessui/react';
import { classnames } from '@/utils/classnames/classnames';
import { asClassStyle } from '@/components/utils/helpers/variants';
import { Props } from './types';
import { useDropdown } from '../../context';

const DropdownOption = ({ children, value }: React.PropsWithChildren<Props>) => {
  const { value: selectedValue, defaultValue } = useDropdown();

  return (
    <Listbox.Option key={value} className="relative cursor-pointer select-none" value={{ value }}>
      <div
        className={classnames(asClassStyle('h6') as string, 'block truncate uppercase', {
          'text-primary': value !== selectedValue && value !== defaultValue,
          'text-pink-500': value === selectedValue || value === defaultValue,
        })}
      >
        {children}
      </div>
    </Listbox.Option>
  );
};

export default DropdownOption;
