import React from 'react';
import Button from '@/components/atoms/button';
import ProductInfo from '@/components/molecules/product-info';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ProductSuggestion } from '../../types';

const SearchPanelItemsLarge = (props: ProductSuggestion & { onClick?: (product: ProductSuggestion) => void }) => {
  const { onClick, ...product } = props;
  const { sku, name, image, id, productKey, inStock, maxQuantity, restockDate } = product;

  const { translate } = useTranslation();

  return (
    <div className="flex items-center justify-between gap-x-2">
      <ProductInfo
        id={id}
        sku={sku}
        name={name}
        image={image}
        productKey={productKey}
        className={'max-w-[350px] '}
        inStock={inStock}
        maxQuantity={maxQuantity}
        restockDate={restockDate}
        isFromSearchResults={true}
      />

      {onClick && (
        <Button
          type="button"
          variant="bordered"
          onClick={() => onClick(product)}
          icon={<PlusIcon className="thick-svg ml-1 text-primary" width={16} height={16} />}
          className="shrink-0"
        >
          {translate('cart.add')}
        </Button>
      )}
    </div>
  );
};

export default SearchPanelItemsLarge;
