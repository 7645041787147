import React from 'react';
import { useParams } from 'next/navigation';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { format } from 'date-fns';
import { getDateLocale } from '@/utils/dates';
import { ProductStatusInfoProps } from './types';
import Typography from '../typography';

const ProductStatusInfo = ({ inStock, maxQuantity, restockDate, isFromSearchResults }: ProductStatusInfoProps) => {
  const { translate } = useTranslation();
  const { locale } = useParams();

  return (
    <div className="flex items-center gap-2 whitespace-pre text-14 text-gray-700">
      {!inStock && <Typography className="!text-pink-500">{translate('common.out.of.stock')}</Typography>}
      {inStock && maxQuantity && (
        <Typography>
          {translate('product.inventory.quantity', {
            values: { maxQuantity: String(maxQuantity) },
          })}
        </Typography>
      )}
      {!isFromSearchResults && restockDate && (
        <Typography>
          {'-' +
            translate('product.inventory.restocking.date', {
              values: {
                date: String(
                  format(restockDate ? new Date(restockDate) : new Date(), 'P', {
                    locale: getDateLocale(locale as string),
                  }),
                ),
              },
            })}
        </Typography>
      )}
    </div>
  );
};

export default ProductStatusInfo;
