import React, { FormEvent } from 'react';
import useDisclosure from '@/hooks/useDisclosure';
import Button from '@/components/atoms/button';
import Overlay from '@/components/atoms/overlay';
import Typography from '@/components/atoms/typography';
import IconButton from '@/components/atoms/icon-button';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import { Props } from './types';

const Modal = ({
  children,
  buttonProps,
  buttonTitle,
  modalTitle,
  cancelButtonTitle,
  validateButtonTitle,
  onValidate,
}: React.PropsWithChildren<Props>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleValidate = (e: FormEvent) => {
    e.preventDefault();
    if (onValidate) onValidate();
    onClose();
  };

  return (
    <>
      <Button {...buttonProps} onClick={onOpen}>
        {buttonTitle}
      </Button>

      {isOpen && (
        <>
          <Overlay onClick={onClose} zIndex="z-[315]" />
          <div className="fixed inset-0 z-[320] flex items-center justify-center">
            <div className="relative w-full max-w-md rounded-2xl border border-white-400 bg-white-100 p-8 shadow-lg">
              <div className="flex flex-col gap-y-4">
                <div className="flex items-start justify-between">
                  <Typography classStyle="h3">{modalTitle}</Typography>
                  <IconButton onClick={onClose} icon={<CloseIcon className="h-6 w-6" />} />
                </div>

                {children}

                <div className="flex justify-start gap-x-4">
                  <Button variant="bordered" onClick={onClose}>
                    {cancelButtonTitle}
                  </Button>
                  <Button onClick={handleValidate}>{validateButtonTitle}</Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Modal;
