'use client';

import React from 'react';
import Login from '@/components/organisms/authentication/login';
import useAccount from '@/lib/hooks/useAccount';
import { AuthLayoutProps } from '@/components/organisms/authentication/types';
import { Reference } from '@/types/lib/reference';
import { TasticProps } from '../../types';

type LoginProps = {
  TOULink: Reference;
};

const AccountLoginTastic = ({ data }: TasticProps<AuthLayoutProps & LoginProps>) => {
  const { login } = useAccount();

  return <Login login={login} {...data} />;
};

export default AccountLoginTastic;
