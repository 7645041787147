import { cva } from '@/utils/classnames/cva';

export const asClassStyle = cva({
  h3: 'font-serif text-32 leading-[32px]',
  h4: 'font-serif text-24 leading-[32px]',
  h5: 'text-16 font-bold leading-5',
  h6: 'text-14 font-bold leading-4',
  b: 'text-16 font-light leading-5',
  b2: 'text-14 font-light leading-4',
  b3: 'text-12 font-light leading-[14px]',
});
