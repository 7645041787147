'use client';

import React from 'react';
import Image from '@/components/atoms/Image';
import Typography from '@/components/atoms/typography';
import { CategoryListItemProps } from './types';

const CategoryListItem = ({ name, image }: CategoryListItemProps) => {
  return (
    <div className="flex h-full">
      <div className="relative block aspect-1 h-full">
        <Image src={image?.url} fill style={{ objectFit: 'cover' }} alt={image?.title ?? name ?? ''} />
      </div>

      <Typography classStyle="h6" className="self-center p-4">
        {name}
      </Typography>
    </div>
  );
};

export default CategoryListItem;
