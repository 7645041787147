import React from 'react';
import CategoryList from '@/components/organisms/category-list';
import { TasticProps } from '../types';
import { Props } from './types';

const CategoryListTastic = async ({ data }: TasticProps<Props>) => {
  return <CategoryList {...data} />;
};

export default CategoryListTastic;
