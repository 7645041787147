import { sdk } from '@/sdk';
import { GetProductQuery, ProductQueryQuery } from '@/sdk/composable-commerce-b2b/types/queries/ProductQueries';
import { Category, Product } from '@shared/types/product';
import useSWR from 'swr';

export const useProducts = (query: ProductQueryQuery | undefined, enabled = true) => {
  const { data } = useSWR(enabled ? ['/action/product/query', query] : null, () =>
    sdk.composableCommerce.product.query(query),
  );

  return data?.isError ? [] : (data?.data.items as Product[]) ?? [];
};

export const useProduct = (query: GetProductQuery) => {
  const { data } = useSWR(['/action/product/getProduct', query], () =>
    sdk.composableCommerce.product.getProduct(query),
  );

  return data?.isError ? undefined : data?.data ?? undefined;
};

export const useProductsByCategory = (categories: Category[], enabled = true) => {
  const { data } = useSWR(enabled ? ['/action/product/querySearch', categories] : null, async () => {
    const productsPromises = categories.map((category) => {
      return sdk.composableCommerce.product.querySearch({
        category: category.categoryId,
        isCommercialOperation: category.isCommercialOperation ?? false,
        limit: 0,
      });
    });

    const productsArraySolved = await Promise.all(productsPromises);
    return productsArraySolved.map((products) => (products.isError ? 0 : products.data.total ?? 0));
  });

  return data ?? [];
};
