import BlogTastic from './blog';
import AccountLoginTastic from './account/login';
import AccountLoginV2Tastic from './account/loginV2';
import AccountRegisterTastic from './account/register';
import ResetPasswordTastic from './account/reset-password';
import ForgotPasswordTastic from './account/forgot-password';
import ContentItemsTastic from './content-items';
import ContentTilesTastic from './content-tiles';
import CommercialOperationListTastic from './commercial-operation-list';
import CategoryListTastic from './category-list';
import DashboardTastic from './dashboard';
import FooterTastic from './footer';
import HeroTileTastic from './hero-tile';
import NotFoundTastic from './not-found';
import HeaderTastic from './header';
import HeaderV2Tastic from './headerV2';
import ProductListTastic from './product-list';
import VerifyTastic from './account/verify';
import VerifyAssociateTastic from './account/verify-associate';
import CartTastic from './cart';
import CartTasticV2 from './cartV2';
import QuotesTastic from './quotes';
import LastOrdersTastic from './orders/last-orders';
import OrdersTastic from './orders';
import CompanyAdminTastic from './company-admin';
import PurchaseListsTastic from './purchase-lists';
import SettingsTastic from './settings';
import AddressesTastic from './addresses';
import PurchaseListDetailTastic from './purchase-list-detail';
import OrderDetailTastic from './order-detail';
import QuoteDetailTastic from './quote-detail';
import CheckoutTastic from './checkout';
import ThankYouTastic from './thank-you';
import ProductSliderTastic from './product-slider';
import ProductDetailsTastic from './product-details';
import QuoteRequestDetailsTastic from './quote-request-detail';
import QuoteCheckoutTastic from './quote-checkout';
import QuoteThankYouTastic from './quote-thank-you';
import SeparatorTastic from './separator';
import HighlightBannerTastic from './highlight-banner';
import AccountPageTastic from './account-page';
import ProductSelectionListTastic from './product-selection-list';
import type { TasticRegistry } from './types';

const tastics = {
  'commercetools/ui/content/hero-tile': HeroTileTastic,
  'commercetools/ui/content/content-items': ContentItemsTastic,
  'commercetools/ui/content/content-tiles': ContentTilesTastic,
  'commercetools/ui/content/commercial-operation-list': CommercialOperationListTastic,
  'commercetools/ui/content/category-list': CategoryListTastic,
  'commercetools/ui/footer': FooterTastic,
  'commercetools/ui/dashboard': DashboardTastic,
  'commercetools/ui/not-found': NotFoundTastic,
  'commercetools/ui/content/blog': BlogTastic,
  'commercetools/ui/header': HeaderTastic,
  'commercetools/ui/headerV2': HeaderV2Tastic,
  'commercetools/ui/products/details': ProductDetailsTastic,
  'commercetools/ui/products/product-list': ProductListTastic,
  'commercetools/ui/products/product-selection-list': ProductSelectionListTastic,
  'commercetools/ui/cart': CartTastic,
  'commercetools/ui/cartV2': CartTasticV2,
  'commercetools/ui/account/login': AccountLoginTastic,
  'commercetools/ui/account/loginV2': AccountLoginV2Tastic,
  'commercetools/ui/account/verify': VerifyTastic,
  'commercetools/ui/account/verify-associate': VerifyAssociateTastic,
  'commercetools/ui/account/register': AccountRegisterTastic,
  'commercetools/ui/account/reset-password': ResetPasswordTastic,
  'commercetools/ui/account/forgot-password': ForgotPasswordTastic,
  'commercetools/ui/quotes': QuotesTastic,
  'commercetools/ui/last-orders': LastOrdersTastic,
  'commercetools/ui/orders': OrdersTastic,
  'commercetools/ui/company-admin': CompanyAdminTastic,
  'commercetools/ui/purchase-lists': PurchaseListsTastic,
  'commercetools/ui/settings': SettingsTastic,
  'commercetools/ui/addresses': AddressesTastic,
  'commercetools/ui/purchase-list-detail': PurchaseListDetailTastic,
  'commercetools/ui/order-detail': OrderDetailTastic,
  'commercetools/ui/quote-detail': QuoteDetailTastic,
  'commercetools/ui/quote-request-detail': QuoteRequestDetailsTastic,
  'commercetools/ui/checkout': CheckoutTastic,
  'commercetools/ui/quote-checkout': QuoteCheckoutTastic,
  'commercetools/ui/thank-you': ThankYouTastic,
  'commercetools/ui/quote-thank-you': QuoteThankYouTastic,
  'commercetools/ui/products/product-slider': ProductSliderTastic,
  'commercetools/ui/separator': SeparatorTastic,
  'commercetools/ui/highlight-banner': HighlightBannerTastic,
  'commercetools/ui/account': AccountPageTastic,
} as TasticRegistry;

export default tastics;
