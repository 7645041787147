'use client';

import { PropsWithChildren } from 'react';
import { resolveReference } from '@/utils/lib/resolve-reference';
import { AuthLayoutProps } from '../types';
import HeaderLogo from '../../header/logo';

const AuthLayout = ({ logo, logoLink, children }: PropsWithChildren<AuthLayoutProps>) => {
  return (
    <div className="flex min-h-[100vh] w-full items-center justify-center bg-white-300">
      <div className="flex w-fit flex-col items-center gap-6 rounded-2xl bg-white-100 p-4 md:p-10">
        <div className="w-[132px]">
          <HeaderLogo logo={logo} logoLink={resolveReference(logoLink)} />
        </div>

        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
