'use client';

import { ChangeEvent, FC, useState } from 'react';
import Input from '@/components/atoms/input';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import Typography from '@/components/atoms/typography';
import { Account } from '@shared/types/account';
import useCustomRouter from '@/hooks/useCustomRouter';
import AuthLayout from '../layouts/auth-layout';
import AuthForm from '../layouts/auth-form';
import { ForgotPasswordProps } from './types';

const ForgotPassword: FC<ForgotPasswordProps> = ({ requestPasswordReset, ...props }) => {
  const router = useCustomRouter();

  const { translate } = useTranslation();

  const [data, setData] = useState<Account>({});
  const [error, setError] = useState<string>();
  const [requested, setRequested] = useState(false);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (error) setError(undefined);

    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const handleRequestPasswordResetSubmit = () => {
    if (data.email) {
      requestPasswordReset(data.email)
        .then(() => {
          setRequested(true);
        })
        .catch(() => {
          setError(translate('error.auth.wrong'));
        });
    } else {
      setError(translate('error.auth.wrong'));
    }
  };

  const goBackToLogin = () => router.push('/login');

  return (
    <AuthLayout {...props}>
      <AuthForm
        title={translate('account.account.forgot.password.headline')}
        onSubmit={requested ? goBackToLogin : handleRequestPasswordResetSubmit}
        buttonLabel={translate(
          requested ? 'account.account.forgot.password.back' : 'account.account.forgot.password.submit',
        )}
        isButtonDisabled={!requested && (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email))}
        error={error}
      >
        {requested ? (
          <Typography classStyle="b2" className="inline">
            {translate('account.password.req.sent.desc')}
          </Typography>
        ) : (
          <>
            <Typography classStyle="b2" className="mb-4">
              {translate('account.account.forgot.password.description')}
            </Typography>
            <Input
              containerClassName="w-full"
              className="w-full"
              name="email"
              required
              label={translate('common.emailAddress')}
              placeholder={translate('common.emailAddress.placeholder')}
              value={data.email ?? ''}
              onChange={handleChange}
            />
          </>
        )}
      </AuthForm>
    </AuthLayout>
  );
};

export default ForgotPassword;
