import { FormEvent, PropsWithChildren } from 'react';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import Typography from '@/components/atoms/typography';
import { AuthFormProps } from '../types';
import { resolveReference } from '../../../../utils/lib/resolve-reference/index';

const AuthForm = ({
  title,
  subtitle,
  subtitleLink,
  subtitleLinkLabel,
  buttonLabel,
  footerLabel,
  footerLink,
  error,
  footerLinkLabel,
  onSubmit,
  isButtonDisabled,
  children,
}: PropsWithChildren<AuthFormProps>) => {
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit?.();
  };

  return (
    <div className="flex w-full max-w-[300px] flex-col gap-6 md:max-w-[400px]">
      <Typography classStyle="h3" className="text-center">
        {title}
      </Typography>

      <form onSubmit={handleSubmit}>
        {children && (
          <div className="grid gap-2">
            {error && (
              <Typography classStyle="b2" className="text-red-500">
                {error}
              </Typography>
            )}

            {children}
          </div>
        )}

        <Button type="submit" size="full" className="mt-6" disabled={isButtonDisabled}>
          {buttonLabel}
        </Button>
      </form>

      {subtitle && (
        <div className="flex flex-col items-center gap-1 text-center">
          <Typography classStyle="b2">{subtitle}</Typography>
          {subtitleLink && subtitleLinkLabel && (
            <Link classStyle="b2" {...resolveReference(subtitleLink)}>
              {subtitleLinkLabel}
            </Link>
          )}
        </div>
      )}

      {subtitle && (footerLabel || footerLinkLabel) && <div className="border border-white-400" />}

      {(footerLabel || (footerLink && footerLinkLabel)) && (
        <div className="flex w-full flex-wrap justify-center gap-1 text-center">
          {footerLabel && <Typography classStyle="b2">{footerLabel}</Typography>}
          {footerLink && footerLinkLabel && (
            <Link href={footerLink} classStyle="b2">
              {footerLinkLabel}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default AuthForm;
