import { HeaderProps } from '@/components/organisms/header/types';
import { Reference } from '@/types/lib/reference';

export interface HeaderTasticProps extends Omit<HeaderProps, 'logoLink' | 'ordersLink' | 'cartLink' | 'accountLink'> {
  logoLink: Reference;
  ordersLink: Reference;
  cartLink: Reference;
  accountLink: Reference;
}

export enum ChannelKey {
  Franchise = '02',
  Wholesale = '03',
}

export enum SearchChannelStatus {
  IN = 'IN',
  PRE_IN = 'PRE-IN',
  PRE_OUT = 'PRE-OUT',
  OUT = 'OUT',
}
