import React, { useMemo } from 'react';
import Overlay from '@/components/atoms/overlay';
import toast from '@/components/atoms/toaster/helpers/toast';
import ProductViewModal from '@/components/molecules/product-view-modal';
import { useProduct } from '@/lib/hooks/useProduct';
import useCart from '@/lib/hooks/useCart';
import useStores from '@/lib/hooks/useStores';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';
import { mapProduct } from '@/utils/mappers/map-product';
import { Props } from './types';

const AddItemToCartForm = ({ close, productSuggestion }: Props) => {
  const { translate } = useTranslation();

  const fetchedProduct = useProduct({ sku: productSuggestion?.sku });
  const product = useMemo(() => fetchedProduct && mapProduct(fetchedProduct), [fetchedProduct]);

  const { selectedBusinessUnit, selectedStore } = useStoreAndBusinessUnits();
  const { priceChannel } = useStores();
  const { cart, addItem, clearCart } = useCart(selectedBusinessUnit?.key, selectedStore?.key);

  const canAddToCart = !(cart?.lineItems?.length && (cart?.isPreOrder ?? false));
  return (
    <>
      {product && (
        <>
          <Overlay zIndex="z-[315]" />
          <ProductViewModal
            item={product}
            close={close}
            canAddToCart={canAddToCart}
            onClearCart={clearCart}
            onAddToCart={async (count: number) => {
              let res;
              if (product.sku) {
                res = await addItem([{ sku: product.sku, count }], priceChannel?.channelId);
              }
              if (!res?.success) toast.error(res?.message || translate('common.something.went.wrong'));
              else {
                close();
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default AddItemToCartForm;
