import React from 'react';
import CommercialOperationList from '@/components/organisms/commercial-operation-list';
import { TasticProps } from '../types';
import { Props } from './types';

const CommercialOperationListTastic = async ({ data }: TasticProps<Props>) => {
  return <CommercialOperationList {...data} />;
};

export default CommercialOperationListTastic;
