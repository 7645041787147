'use client';

import React from 'react';
import { useCategories } from '@/lib/hooks/useCategories';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import CategoryListItem from '@/components/molecules/category-list-item';
import SectionHeader from '@/components/molecules/section-header';
import Link from '@/components/atoms/link';
import { CategoryListItemProps } from '@/components/molecules/category-list-item/types';
import LoadingIcon from '@/components/atoms/loading-icon';
import Typography from '@/components/atoms/typography';
import { CategoryListProps } from './types';

const CategoryList = ({ title }: CategoryListProps) => {
  const { translate } = useTranslation();
  const { categories: items, isLoading } = useCategories({
    isRootCategory: true,
    isNotCommercialOperation: true,
  });

  const categories = items?.sort((catA, catB) => {
    if (catA.name && catB.name && catA.name < catB.name) return -1;
    if (catA.name && catB.name && catA.name > catB.name) return 1;
    return 0;
  });

  const categoriesData = categories?.map<CategoryListItemProps>((category) => ({
    name: category.name ?? '',
    slug: category.slug ?? '',
    image: category.images?.[0],
  }));

  return categories ? (
    <div className="m-4 bg-white-100 lg:mx-7 lg:my-14">
      <SectionHeader title={title} />
      <div className="grid w-full grid-cols-6 justify-stretch gap-8">
        {isLoading ? (
          <LoadingIcon svgWidth={20} svgHeight={20} className={'fill-gray-700'} />
        ) : !categoriesData?.length ? (
          <Typography classStyle="b">{translate('common.no.results.found')}</Typography>
        ) : (
          categoriesData.map((item, index) => (
            <Link
              key={`${item.name} - ${index}`}
              title={item.name}
              href={item.slug}
              className="!w-auto flex-1 border border-white-400"
              underline={false}
              underlineOnHover={false}
            >
              <CategoryListItem {...item} />
            </Link>
          ))
        )}
      </div>
    </div>
  ) : null;
};

export default CategoryList;
