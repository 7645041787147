import { sdk } from '@/sdk';
import { Category } from '@shared/types/product/Category';
import useSWR from 'swr';
import { QueryProductCategoriesQuery } from '@/sdk/composable-commerce-b2b/types/queries/ProductQueries';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';

export const useCategories = (options?: QueryProductCategoriesQuery) => {
  const query: QueryProductCategoriesQuery = options ?? { format: 'tree', limit: 500 };
  const { selectedBusinessUnit, selectedStore } = useStoreAndBusinessUnits();

  const { data, isLoading } = useSWR(
    ['/action/product/queryCategories', query, selectedBusinessUnit, selectedStore],
    () => sdk.composableCommerce.product.queryCategories(query),
  );

  if (data?.isError) return { categories: [], isLoading: false };

  const categories = (data?.data.items as Category[]) ?? [];

  return { categories, isLoading };
};
