'use client';

import dynamic from 'next/dynamic';
import { resolveReference } from '@/utils/lib/resolve-reference';
import { OrdersTasticProps } from './components/types';
import { TasticProps } from '../types';

const OrdersClientWrapper = dynamic(() => import('./components/orders-client-wrapper'));

const OrdersTastic = ({ data }: TasticProps<OrdersTasticProps>) => {
  const ordersData = {
    ...data,
    link: resolveReference(data.link),
  };

  return <OrdersClientWrapper {...ordersData} />;
};

export default OrdersTastic;
