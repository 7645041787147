import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { classnames } from '@/utils/classnames/classnames';
import { useDropdown } from '../../context';
import { Props } from './types';

const DropdownOptions = ({ children, className }: React.PropsWithChildren<Props>) => {
  const { isExpanded } = useDropdown();

  return (
    <Transition
      show={isExpanded}
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Listbox.Options
        static
        className={classnames(
          'absolute z-[320] mt-4 grid max-h-60 w-full gap-y-6 overflow-y-auto rounded-2xl border border-white-400 bg-white-100 p-4 focus:outline-none',
          className,
        )}
      >
        {children}
      </Listbox.Options>
    </Transition>
  );
};

export default DropdownOptions;
