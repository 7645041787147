'use client';
import React from 'react';
import Header from '@/components/organisms/header';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import useMediaQuery from '@/hooks/useMediaQuery';
import { desktop } from '@/constants/screensizes';
import { resolveReference } from '@/utils/lib/resolve-reference';
import { mapProductSuggestion } from '@/utils/mappers/map-product-suggestion';
import useAccountRoles from '@/lib/hooks/useAccountRoles';
import { TasticProps } from '../types';
import { HeaderTasticProps } from './types';
import useHeaderData from './hooks/useHeaderData';

const HeaderTastic = ({ data }: TasticProps<HeaderTasticProps>) => {
  const { translate } = useTranslation();
  const [isDesktopSize] = useMediaQuery(desktop);

  const {
    businessUnits,
    selectedBusinessUnit,
    stores,
    selectedStore,
    quotes,
    totalCartItems,
    headerSearch,
    searchSuggestions,
    quickOrderSearch,
    quickOrderProducts,
    csvShowProducts,
    addToCart,
    onHeaderSearch,
    onQuickOrderSearch,
    onBusinessUnitSelect,
    onStoreSelect,
    // headerSearchAction,
    handleSKUsUpdate,
  } = useHeaderData();

  const { isAdmin } = useAccountRoles(selectedBusinessUnit);

  const { permissions } = useAccountRoles(selectedBusinessUnit);

  return (
    <div className="h-[81px]">
      <div id="header-container" className="fixed top-0 z-50 w-full">
        <div className="relative">
          <Header
            isAdmin={isAdmin}
            csvDownloadLink="/template.csv"
            quickOrderProducts={quickOrderProducts.map((product) => mapProductSuggestion(product))}
            addToCartDisabled={!permissions.UpdateMyCarts}
            selectedBusinessUnit={selectedBusinessUnit}
            businessUnits={businessUnits}
            stores={stores}
            selectedStore={selectedStore}
            accountLink={resolveReference(data.accountLink)}
            accountLinkLabel={data.accountLinkLabel}
            cartItems={totalCartItems}
            cartLink={resolveReference(data.cartLink)}
            cartLinkLabel={data.cartLinkLabel}
            logo={data.logo}
            logoLink={resolveReference(data.logoLink)}
            ordersLink={resolveReference(data.ordersLink)}
            ordersLinkLabel={data.ordersLinkLabel}
            searchPlaceholder={
              isDesktopSize ? translate('common.search.placeholder') : translate('common.search.placeholder.mobile')
            }
            searchSuggestions={searchSuggestions}
            quotes={quotes?.length ?? 0}
            onBusinessUnitChange={onBusinessUnitSelect}
            onStoreChange={onStoreSelect}
            quickOrderSearch={quickOrderSearch}
            onQuickOrderSearch={onQuickOrderSearch}
            headerSearch={headerSearch}
            onHeaderSearch={onHeaderSearch}
            // onHeaderSearchAction={headerSearchAction}
            addToCart={addToCart}
            csvShowProducts={csvShowProducts}
            handleSKUsUpdate={handleSKUsUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderTastic;
