import { cva } from '@/utils/classnames/cva';
import { classnames } from '@/utils/classnames/classnames';
import { asClassStyle } from '@/components/utils/helpers/variants';
import { DropdownProps } from '../../types';

const useClassNames = ({ disabled }: Partial<DropdownProps>) => {
  const intent = disabled ? 'disabled' : 'active';

  const resolveButtonVariant = cva({
    intent: {
      active: 'cursor-pointer border-white-400 bg-white-100 text-primary focus:shadow-200',
      disabled: 'cursor-not-allowed border-white-300 bg-neutral-200 text-primary',
    },
    size: {
      sm: 'h-[32px]',
      lg: 'h-[40px]',
    },
  });

  const buttonClassName = classnames(
    asClassStyle('h6') as string,
    'flex w-full gap-4 rounded-full border bg-white-100 px-4 py-3 text-left uppercase focus:outline-none',
    resolveButtonVariant(`intent.${intent}`) as string,
    // resolveButtonVariant(`size.${size}`) as string,
  );

  return { buttonClassName };
};

export default useClassNames;
