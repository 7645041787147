import { Category } from '@shared/types/product';
import { CommercialOperation } from '@shared/types/custom-object/CommercialOperation';

export const mapCommercialOperation = (category: Category, products: number): CommercialOperation => {
  return {
    title: category.name,
    products: products,
    startDate: category.startDate,
    endDate: category.endDate,
    slug: category.slug,
    image: category.images?.[0]?.url,
    link: { href: category._url },
  } as unknown as CommercialOperation;
};
