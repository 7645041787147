import React, { useCallback, useState } from 'react';
import { useParams } from 'next/navigation';
import Image from '@/components/atoms/Image';
import Input from '@/components/atoms/input';
import Button from '@/components/atoms/button';
import Typography from '@/components/atoms/typography';
import IconButton from '@/components/atoms/icon-button';
import ProductStatusTag from '@/components/atoms/product-status-tag';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import useFormat from '@/hooks/useFormat';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { classnames } from '@/utils/classnames/classnames';
import { format } from 'date-fns';
import { getDateLocale } from '@/utils/dates';
import { ProductViewModalProps } from './types';
import Modal from '../modal';

const ProductViewModal = ({
  item: {
    productKey,
    name,
    specifications,
    inStock,
    sku,
    price,
    currency,
    images,
    restockableInDays,
    unitPrice,
    maxQuantity,
    restockDate,
  },
  onAddToCart,
  variant = 'list-item',
  close,
  onClearCart,
  canAddToCart,
}: ProductViewModalProps) => {
  const { translate } = useTranslation();
  const { locale } = useParams();

  const [addingToCart, setAddingToCart] = useState(false);

  const [quantity, setQuantity] = useState(1);

  const [addToCartDisabled, setAddToCartDisabled] = useState(false);

  const handleAddToCart = useCallback(async () => {
    setAddingToCart(true);
    await onAddToCart?.(quantity);
    setAddingToCart(false);
    setQuantity(1);
  }, [onAddToCart, quantity]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (!isNaN(+value)) setQuantity(value);
    if (value < 1) {
      setAddToCartDisabled(true);
    } else {
      setAddToCartDisabled(false);
    }
  }, []);

  const { formatCurrency } = useFormat();

  const clearAndAdd = () => {
    onClearCart?.().then(() => {
      handleAddToCart();
    });
  };

  const packaging = specifications?.find((spec) => spec.label === 'packaging')?.value ?? '1';

  return (
    <div className="fixed inset-0 z-[320] flex items-center justify-center">
      <div className="relative max-h-[80vh] w-full max-w-3xl overflow-y-auto rounded-2xl border border-white-400 bg-white-100 p-8 shadow-lg">
        <div className="flex flex-row gap-x-8">
          <div className={'relative h-52 w-52 shrink-0'}>
            <Image fill src={images?.[0]} alt={name} style={{ objectFit: 'contain' }} />
          </div>
          <div className="flex flex-col">
            <div className={classnames('overflow-hidden', { 'md:grow': variant === 'list-item' })}>
              <div className="mb-1 flex flex-row gap-x-2">
                <Typography classStyle="h3">{name}</Typography>
                <ProductStatusTag inStock={inStock && Boolean(price)} restockableInDays={restockableInDays} />
              </div>
              <Typography classStyle="b3">{`${productKey} - ${sku}`}</Typography>
              {inStock && maxQuantity && (
                <Typography classStyle="b3">
                  {translate('product.inventory.quantity', {
                    values: { maxQuantity: String(maxQuantity) },
                  })}
                </Typography>
              )}
              {restockDate && (
                <Typography classStyle="b3">
                  {translate('product.inventory.restocking.date', {
                    values: {
                      date: String(
                        format(restockDate ? new Date(restockDate) : new Date(), 'P', {
                          locale: getDateLocale(locale as string),
                        }),
                      ),
                    },
                  })}
                </Typography>
              )}
            </div>
            <div
              className={classnames('flex flex-col', {
                'gap-4 md:gap-5 lg:gap-6': variant === 'grid-item',
              })}
            >
              <div
                className={classnames('flex flex-row gap-x-2', {
                  invisible: !price,
                })}
              >
                <div className="w-28 shrink-0 truncate">
                  <Typography classStyle="h6">{formatCurrency(price, currency)}</Typography>
                  <Typography classStyle="b3">
                    {translate('product.unit.price.packaging', {
                      values: {
                        unitPrice: formatCurrency(unitPrice || price, currency),
                        packaging: packaging.toString(),
                      },
                    })}
                  </Typography>
                </div>
              </div>
              <div className={'mb-4 mt-2 flex gap-x-4'}>
                <Input
                  type="number"
                  placeholder="0"
                  value={quantity}
                  onChange={handleChange}
                  containerClassName="w-24 rounded-lg !border-secondary"
                  className="py-[8px] pr-3 text-right"
                  min={0}
                />
                <Typography classStyle="b2" className="content-center">
                  {translate('product.number.of.units', {
                    values: { totalUnits: (Number(packaging) * (quantity ?? 0)).toString() },
                  })}
                </Typography>
              </div>

              <div className="flex items-center gap-3">
                {canAddToCart ? (
                  <Button
                    variant="primary"
                    onClick={handleAddToCart}
                    loading={addingToCart}
                    disabled={addToCartDisabled}
                  >
                    {translate('cart.add')}
                  </Button>
                ) : (
                  <Modal
                    buttonProps={{ variant: 'primary' }}
                    buttonTitle={translate('cart.add')}
                    modalTitle={translate('common.warning').toUpperCase()}
                    cancelButtonTitle={translate('common.cancel')}
                    validateButtonTitle={translate('common.validate')}
                    onValidate={clearAndAdd}
                  >
                    <Typography classStyle="b2">{translate('cart.clearCart.listing.description')}</Typography>
                  </Modal>
                )}
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <IconButton onClick={close} icon={<CloseIcon className="h-6 w-6" />} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductViewModal;
