import React from 'react';
import Typography from '@/components/atoms/typography';
import Image from '@/components/atoms/Image';
import { classnames } from '@/utils/classnames/classnames';
import ProductStatusInfo from '@/components/atoms/product-status-info';
import { ProductInfoProps } from './types';

const ProductInfo = ({
  sku,
  name,
  image,
  productKey,
  className,
  inStock,
  maxQuantity,
  restockDate,
  isFromSearchResults = false,
}: ProductInfoProps) => {
  return (
    <div className={classnames('flex flex-row items-center gap-x-2 overflow-hidden', className)}>
      <div className="relative h-10 w-10 shrink-0">
        <Image fill src={image} alt={name} style={{ objectFit: 'cover' }} />
      </div>
      <div className="overflow-hidden">
        <div className="flex gap-x-2">
          <Typography classStyle="h6" className="truncate">
            {name}
          </Typography>
          <ProductStatusInfo
            inStock={inStock}
            maxQuantity={maxQuantity}
            restockDate={restockDate}
            isFromSearchResults={isFromSearchResults}
          />
        </div>

        <Typography classStyle="b3" className="truncate">{`${productKey ?? ''} - ${sku}`}</Typography>
      </div>
    </div>
  );
};

export default ProductInfo;
