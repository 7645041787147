import { classnames } from '@/utils/classnames/classnames';
import { cva } from '@/utils/classnames/cva';

const useClassNames = ({ variant = 'primary', padding = 'p-2', className = '' }) => {
  const resolveVariant = cva({
    intent: {
      primary: 'bg-white-300',
      secondary: 'bg-pink-900',
    },
  });

  return classnames('rounded-full', padding, resolveVariant(`intent.${variant}`) as string, className);
};

export default useClassNames;
