'use client';

import React from 'react';
import dynamic from 'next/dynamic';
import { CategoryListItemProps, CommonProps } from '@/providers/cart/types';
import { Category } from '@shared/types/product';
import { useCategories } from '@/lib/hooks/useCategories';
import { DataSourceProps, ViewModelProps } from '../../types';
import { findCategoryBySlug } from '../../utils';

const ProductSelectionListViewModel = dynamic(() => import('../product-selection-list-view-model'));

const ProductSelectionListClientWrapper = (
  props: Omit<CommonProps, 'products' | 'categories'> &
    Omit<DataSourceProps, 'category'> &
    Omit<ViewModelProps, 'categories' | 'category'>,
) => {
  const { slug } = props;

  const { categories } = useCategories();
  const category = findCategoryBySlug(categories, slug);

  const mapCategories = (cat: Category[]): CategoryListItemProps[] =>
    cat
      .reduce<CategoryListItemProps[]>(
        (acc, c) =>
          !c.isCommercialOperation
            ? [
                ...acc,
                {
                  id: c.categoryId ?? '',
                  name: c.name ?? '',
                  slug: c.slug ?? '',
                  parentId: c.parentId ?? '',
                  isCommercialOperation: c.isCommercialOperation,
                  subCategories: mapCategories(c.subCategories),
                },
              ]
            : acc,
        [],
      )
      .sort((catA, catB) => {
        if (catA.name < catB.name) return -1;
        if (catA.name > catB.name) return 1;
        return 0;
      });

  const categoriesData = mapCategories(categories);

  return <ProductSelectionListViewModel {...props} categories={categoriesData} category={category} />;
};

export default ProductSelectionListClientWrapper;
