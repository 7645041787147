import React, { useContext } from 'react';
import Typography from '@/components/atoms/typography';
import Link from '@/components/atoms/link';
import { HeaderContext } from '../context';

const CartLink = () => {
  const { cartLink, cartLinkLabel, cartItems } = useContext(HeaderContext);

  return (
    <Link
      href={cartLink.href ?? '/'}
      className="flex items-center justify-center gap-2"
      underline={false}
      underlineOnHover={false}
    >
      <Typography classStyle="h6" className="uppercase">
        {cartLinkLabel}
      </Typography>

      {cartItems > 0 && <div className="h-2 w-2 rounded-full bg-pink-900" />}
    </Link>
  );
};

export default CartLink;
