'use client';

import dynamic from 'next/dynamic';
import { resolveReference } from '@/utils/lib/resolve-reference';
import { TasticProps } from '../../types';
import { OrdersTasticProps } from '../components/types';

const OrdersClientWrapper = dynamic(() => import('../components/orders-client-wrapper'));

const LastOrdersTastic = ({ data }: TasticProps<OrdersTasticProps>) => {
  const ordersData = {
    ...data,
    link: resolveReference(data.link),
  };

  return <OrdersClientWrapper {...ordersData} />;
};

export default LastOrdersTastic;
