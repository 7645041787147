import React from 'react';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { classnames } from '@/utils/classnames/classnames';
import { asClassStyle } from '@/components/utils/helpers/variants';
import { LabelProps } from './types';

const Label = ({
  children,
  required,
  showOptionalLabel,
  optionalLabel,
  requiredStyle = 'asterisk',
}: React.PropsWithChildren<LabelProps>) => {
  const { translate } = useTranslation();

  if (!children && !required) return <></>;

  const labelClassName = asClassStyle('b3') as string;

  return (
    <div className="flex items-center justify-between">
      {children && (
        <label className={classnames('block', labelClassName)}>
          <span className={labelClassName}>{children}</span>{' '}
          {!required && showOptionalLabel && (
            <span className={classnames(labelClassName, 'lowercase text-secondary')}>
              ({optionalLabel ?? translate('common.optional')})
            </span>
          )}
          {required && requiredStyle === 'asterisk' && <span className={labelClassName}>*</span>}
        </label>
      )}
      {required && requiredStyle === 'label' && (
        <span className={labelClassName}>{translate('common.field.required')}</span>
      )}
    </div>
  );
};

export default Label;
