'use client';

import React from 'react';
import Link from '@/components/atoms/link';
import Typography from '@/components/atoms/typography';
import { SectionHeaderProps } from './types';

const SectionHeader = ({ title, link }: SectionHeaderProps) => {
  return (
    <div className="mb-6">
      {title && (
        <Typography as="h3" classStyle="h3">
          {title}
        </Typography>
      )}
      {link?.href && (
        <Link
          href={link.href ?? '#'}
          openInNewTab={link.openInNewTab}
          className="mx-auto mt-4 text-14 font-medium text-primary lg:text-16"
          chevron
        >
          {link.name}
        </Link>
      )}
    </div>
  );
};

export default SectionHeader;
