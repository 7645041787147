import React, { FC } from 'react';
import useClassNames from './hooks/useClassNames';
import { IconButtonProps } from './types';

const IconButton: FC<IconButtonProps> = ({ variant = 'primary', icon, padding = 'p-2', className = '', ...props }) => {
  const buttonClassName = useClassNames({ variant, padding, className });

  return (
    <button {...props} className={buttonClassName}>
      {icon}
    </button>
  );
};

export default IconButton;
