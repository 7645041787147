'use client';

import dynamic from 'next/dynamic';

const CartClientWrapper = dynamic(() => import('./components/cart-client-wrapper'));

const CartTasticV2 = async () => {
  return <CartClientWrapper />;
};

export default CartTasticV2;
