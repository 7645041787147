import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import useCustomRouter from '@/hooks/useCustomRouter';
import { Option } from '@/components/atoms/select/types';
import usePath from '@/hooks/usePath';
import { ContextShape, Location, LocationFull } from '@/components/organisms/shipping-and-language/types';
import { useSWRConfig } from 'swr';
import { sdk } from '@/sdk';
import { getLocalizationInfo } from '@/project.config';
import useTranslation from '../I18n/hooks/useTranslation';

const initialMarketState = {
  selectedLanguage: {} as Option,
  selectedLocation: {} as Location,
  locations: [] as Location[],
} as ContextShape;

export const ShipAndLanguageContext = createContext(initialMarketState);

const ShipAndLanguageProvider = ({ children }: React.PropsWithChildren) => {
  const { mutate } = useSWRConfig();
  const router = useCustomRouter();
  const { path } = usePath();

  const { locale } = useParams();

  useEffect(() => {
    void mutate((key: string[]) => key?.[0].startsWith('/action/cart/getCart'), undefined, { revalidate: true });
  }, [locale, mutate]);

  const [selectedLocationValue, setSelectedLocationValue] = useState((locale as string).split('-')[1]);
  const [locations, setLocations] = useState<LocationFull[]>([]);

  const { translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      const response = await sdk.composableCommerce.project.getSettings();
      if (!response.isError) {
        const locations = response.data?.languages?.map((localeCode: string) => {
          const locale = localeCode.toLowerCase();
          const location = getLocalizationInfo(locale) as LocationFull;
          location.flagName = locale.split('-')[1];
          location.name = translate(`common.country.${location.countryCode}`);
          location.label = `${translate(`common.country.${location.countryCode}`)} (${location.currency})`;
          location.value = locale.split('-')[1];
          location.defaultLanguage = locale;
          location.languages = [{ name: `${location.countryCode} - ${location.currencyCode}`, value: locale }];
          return location;
        }) as LocationFull[] | undefined;

        if (locations) setLocations(locations);
      }
    }
    void fetchData();
  }, [translate]);

  const selectedLocation = locations.find((location) => location.value === selectedLocationValue);
  const selectedLanguage = selectedLocation?.languages.find((language) => language.value === locale);

  const onLanguageSelect = (language: string) => {
    router.push(path, { locale: language });
  };

  const onLocationSelect = (location: string) => {
    const locationObject = locations.find((l) => l.value === location);

    setSelectedLocationValue(location);

    if (!locationObject?.languages.find((language) => language.value === locale))
      onLanguageSelect(locationObject?.defaultLanguage ?? '');
  };

  return (
    <ShipAndLanguageContext.Provider
      value={{
        selectedLanguage,
        selectedLocation,
        locations,
        onLanguageSelect,
        onLocationSelect,
      }}
    >
      {children}
    </ShipAndLanguageContext.Provider>
  );
};
export default ShipAndLanguageProvider;
export const useShipAndLanguage = () => useContext(ShipAndLanguageContext);
