import React, { useContext, useRef } from 'react';
import Button from '@/components/atoms/button';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import { CheckIcon, XMarkIcon as CloseIcon } from '@heroicons/react/24/outline';
import Image from '@/components/atoms/Image';
import Typography from '@/components/atoms/typography';
import IconButton from '@/components/atoms/icon-button';
import FileIcon from 'public/images/file.svg';
import { QuickOrderDesktopContext } from '../context';

type UploadPanelProps = {
  onClose: () => void;
};

const UploadPanel = ({ onClose }: UploadPanelProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const { translate } = useTranslation();
  const {
    files,
    downloadLink,
    loading,
    loadingProgress,
    fileError,
    handleChange,
    handleClearFiles,
    handleClearReadFiles,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleRemoveClick,
    handleUploadClick,
  } = useContext(QuickOrderDesktopContext);

  const handleClearClick = () => {
    handleClearFiles();
    handleClearReadFiles();
  };

  const fileNameClassNames = (file: File) => {
    return `rounded-md border ${fileError[file.name] && 'border-red-500'} ${!loading[file.name] && 'border-green-500'}`;
  };

  const loadingBarClassName = (file: File) => `w-[${loadingProgress[file.name]}%] bg-primary h-1`;

  const isUploadEnabled = files.length !== 0 && Object.keys(fileError).length === 0;

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-start justify-between">
        <div>
          <Typography classStyle="h3">{translate('quick-order.add.csv')}</Typography>
          <a href={downloadLink} download="template" target="_blank" rel="noopener noreferrer">
            <Button variant="underlined" className="mt-4 font-bold">
              {translate('quick-order.download.csv')}
            </Button>
          </a>
        </div>

        <IconButton onClick={onClose} icon={<CloseIcon className="h-6 w-6" />} />
      </div>

      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => inputFile?.current?.click()}
        className="flex h-[200px] w-full cursor-pointer flex-col items-center justify-center rounded-2xl border border-dashed border-secondary"
      >
        <input type="file" multiple onChange={handleChange} ref={inputFile} className="hidden" />

        <IconButton
          variant={isUploadEnabled ? 'secondary' : 'primary'}
          icon={
            isUploadEnabled ? <CheckIcon className="h-6 w-6 text-white-100" /> : <Image src={FileIcon} alt="Upload" />
          }
          padding="p-4"
        />
        <div className="mt-4">
          <Typography align="center" classStyle="h6">
            {translate('quick-order.drag.csv')}
          </Typography>
          <Typography align="center" classStyle="b2" className="mt-1">
            {translate('quick-order.click.csv')}
          </Typography>
        </div>
      </div>

      {files.length > 0 && (
        <div className="flex flex-col gap-y-3">
          {files.map((file) => (
            <div className="relative" key={file.lastModified}>
              <div className={fileNameClassNames(file)}>
                <div className="flex justify-between px-2 py-3">
                  <Typography fontSize={14} className="truncate">
                    {file.name}
                  </Typography>
                  <Button variant="ghost" size="fit" onClick={() => handleRemoveClick(file)}>
                    <CloseIcon className="w-4" />
                  </Button>
                </div>
                {!fileError[file.name] && loadingProgress[file.name] < 100 && (
                  <div className={loadingBarClassName(file)} />
                )}
              </div>
              {fileError[file.name] && (
                <Typography fontSize={14} className="mt-3 text-red-500">
                  {fileError[file.name]}
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}

      {isUploadEnabled && (
        <div className="flex gap-x-4">
          <Button variant="bordered" onClick={handleClearClick}>
            {translate('quick-order.click.clear')}
          </Button>
          <Button variant="primary" onClick={() => handleUploadClick(files)}>
            {translate('quick-order.click.upload')}
          </Button>
        </div>
      )}
    </div>
  );
};
export default UploadPanel;
