import { useCallback } from 'react';
import { Store } from '@shared/types/store/Store';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';
import { sdk } from '@/sdk';
import { ChannelKey } from '@/lib/tastics/headerV2/types';
import useSWR from 'swr';
import { StoreChannels } from '@shared/types/store/Channel';

const useStores = () => {
  const { selectedBusinessUnit, selectedStore } = useStoreAndBusinessUnits();

  const stores = selectedBusinessUnit?.stores ?? [];

  const defaultStore = stores[0] as Store | undefined;

  const selectedBusinessDefaultShippingAddress = selectedBusinessUnit?.addresses?.find(
    (address) => address.isDefaultShipping,
  );

  const selectedBusinessDefaultBillingAddress = selectedBusinessUnit?.addresses?.find(
    (address) => address.isDefaultBilling,
  );

  const getStoreChannel = useCallback(async (): Promise<StoreChannels> => {
    const result = await sdk.composableCommerce.store.getStoreChannel({
      storeKey: selectedStore?.key as string,
    });

    if (result.isError) {
      return {
        distributionChannels: [],
        supplyChannels: [],
      };
    }

    return result.data as StoreChannels;
  }, [selectedStore]);

  const { data } = useSWR<StoreChannels>(
    !selectedStore?.key ? null : ['/action/store/getStoreChannel', selectedStore.key],
    getStoreChannel,
  );

  const distributionChannels = data?.distributionChannels ?? [];
  const supplyChannels = data?.supplyChannels ?? [];

  return {
    stores,
    defaultStore,
    channel: distributionChannels.find(
      (channel) => channel?.key === ChannelKey.Franchise || channel?.key === ChannelKey.Wholesale,
    ),
    priceChannel: distributionChannels.find(
      (channel) => channel?.key !== ChannelKey.Franchise && channel?.key !== ChannelKey.Wholesale,
    ),
    supplyChannels,
    selectedBusinessDefaultShippingAddress,
    selectedBusinessDefaultBillingAddress,
    getStoreChannel,
  };
};

export default useStores;
