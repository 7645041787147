import { ComposableCommerceEventsB2B } from '../../types/events/ComposableCommerceEventsB2B';
import { SDK, ServerOptions } from '@commercetools/frontend-sdk';
import { GetStoreAction } from '../../types/actions/StoreActions';
import { GetStorePayload } from '../../types/payloads/StorePayloads';
import { StoreChannels } from '@shared/types/store/Channel';

export type StoreActions = {
  getStoreChannel: GetStoreAction;
};

export const getStoreActions = (sdk: SDK<ComposableCommerceEventsB2B>): StoreActions => {
  return {
    getStoreChannel: async (
      payload: GetStorePayload,
      options?: {
        serverOptions?: ServerOptions;
      },
    ) => {
      const response = await sdk.callAction<StoreChannels>({
        actionName: 'store/getStoreChannel',
        payload,
        serverOptions: options?.serverOptions,
      });
      return response;
    },
  };
};
