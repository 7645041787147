import React from 'react';
import useDisclosure from '@/hooks/useDisclosure';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import Button from '@/components/atoms/button';
import Overlay from '@/components/atoms/overlay';
import { QuickOrderDesktopProps } from '../types';
import { QuickOrderDesktopProvider } from './quick-order-csv-upload/context';
import UploadPanel from './quick-order-csv-upload/upload-panel';

const QuickOrderDesktop = ({ downloadLink, csvProducts, addItem, handleSKUsUpdate }: QuickOrderDesktopProps) => {
  const { translate } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <QuickOrderDesktopProvider
      csvProducts={csvProducts}
      downloadLink={downloadLink}
      addItem={addItem}
      handleSKUsUpdate={handleSKUsUpdate}
      onClose={onClose}
    >
      <Button type="button" onClick={onOpen} variant="secondary">
        {translate('quick-order.quick.order')}
      </Button>

      {isOpen && (
        <>
          <Overlay onClick={onClose} zIndex="z-[315]" />
          <div className="fixed inset-0 z-[320] flex items-center justify-center">
            <div className="relative w-full max-w-2xl rounded-2xl border border-white-400 bg-white-100 p-6 shadow-lg">
              <UploadPanel onClose={onClose} />
            </div>
          </div>
        </>
      )}
    </QuickOrderDesktopProvider>
  );
};
export default QuickOrderDesktop;
