import React from 'react';
import { DataSource } from '@/types/lib/datasources';
import { TasticProps } from '../types';
import { DataSourceProps } from '../product-selection-list/types';
import ProductSelectionListClientWrapper from './components/product-selection-list-client-wrapper';

const ProductSelectionListTastic = async ({ data }: TasticProps<DataSource<DataSourceProps>>) => {
  if (!data.data?.dataSource) return <></>;

  const slug = data.data.dataSource?.category?.split('/')?.at(-1) ?? '';

  return <ProductSelectionListClientWrapper {...data.data.dataSource} slug={slug} />;
};

export default ProductSelectionListTastic;
