import { cva } from '@/utils/classnames/cva';
import { classnames } from '@/utils/classnames/classnames';
import { SearchVariant } from '@/components/atoms/search-input/types';
import useMediaQuery from '@/hooks/useMediaQuery';
import { tablet } from '@/constants/screensizes';

const useClassNames = (variant: SearchVariant, focused: boolean) => {
  const [isLargerThanTablet] = useMediaQuery(tablet);
  // const resolveSearchWrapper = cva({
  //   xs: 'rounded-md',
  //   sm: 'rounded-md',
  //   lg: classnames(focused ? 'rounded-t-md' : 'rounded-sm'),
  // });
  const resolveSearchPanel = cva({
    xs: 'top-[48px] max-h-[200px] rounded-md py-2 shadow-500',
    sm: 'top-[52px] max-h-[288px] rounded-md shadow-500',
    lg: 'top-[60px] max-h-full md:mt-0 md:max-h-[404px] lg:top-[66px] lg:max-h-[460px]',
  });

  const SearchWrapperClassNames = classnames(
    focused && !isLargerThanTablet && variant === 'lg'
      ? 'absolute left-0 top-0 z-[450] h-[100vh] w-full'
      : 'relative z-[310] rounded-lg',
    'bg-white-100',
    // resolveSearchWrapper(variant) as string,
  );

  const searchPanelClassNames = classnames(
    'absolute z-[310] mt-4 grid w-[600px] gap-y-4 overflow-y-visible rounded-2xl border border-white-400 bg-white-100 p-4',
    resolveSearchPanel(variant) as string,
  );

  return {
    SearchWrapperClassNames,
    searchPanelClassNames,
  };
};

export default useClassNames;
