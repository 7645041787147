import { enUS, es, fr } from 'date-fns/locale';

export const getDateLocale = (locale: string | undefined) => {
  switch (locale) {
    case 'fr-fr':
      return fr;
    case 'es-es':
      return es;
    case 'en-us':
    default:
      return enUS;
  }
};
